import App from 'next/app';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../assets/css/style.scss';
import { createContext } from 'react';
import { fetchAPI } from '../lib/api';
import { default as Head } from '../components/layout/header';

// Store Strapi Global object in context
export const GlobalContext = createContext({});

const ARDHelpdeskApp = ({ Component, pageProps }) => {
  const { global } = pageProps;

  return (
    <>
      <Head {...pageProps} />
      <GlobalContext.Provider value={global}>
        <Component {...pageProps} />
      </GlobalContext.Provider>
    </>
  );
};

// getInitialProps disables automatic static optimization for pages that don't
// have getStaticProps. So article, category and home pages still get SSG.
// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949
ARDHelpdeskApp.getInitialProps = async (ctx) => {
  const theme = ctx.req?.cookies?.theme ?? 'light';
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(ctx);
  // Fetch global site settings from Strapi
  const globalRes = await fetchAPI('/global', {
    populate: 'deep',
  });

  // Pass the data to our page via props
  return { ...appProps, pageProps: { global: { ...globalRes?.data?.attributes, ...{ selectedTheme: theme } } } };
};

export default ARDHelpdeskApp;
