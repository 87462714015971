import React, { useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { trackPageDisplay } from '../../scripts/tracking/tracking';
import { useNavigationEvent } from '../../lib/useNavigationEvent';

const Header = (data) => {
  const dataAvailable = !(Object.keys(data).length === 0);

  if (dataAvailable) {
    const title = data?.headline ? `${data.headline} - ${APP_SETTINGS.pageTitle}` : APP_SETTINGS.pageTitle;

    useNavigationEvent(() => {
      trackPageDisplay(window, data, title);
    });

    useEffect(() => {
      trackPageDisplay(window, data, title);
    }, [title, data, title]);
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="language" content="de"></meta>
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"></meta>
        <link rel="apple-touch-icon" sizes="180x180" href="/static/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/static/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/static/favicons/favicon-16x16.png" />
        <link rel="manifest" href="/static/favicons/site.webmanifest" />
        <link rel="mask-icon" href="/static/favicons/safari-pinned-tab.svg" color="#003480" />
        <link rel="shortcut icon" href="/static/favicons/favicon.ico" />
        {dataAvailable && <link rel="preconnect" href={`${APP_SETTINGS.trackingUri}`} type="application/javascript" crossOrigin="anonymous" />}

        <meta name="apple-mobile-web-app-title" content="ARD Helpdesk" />
        <meta name="application-name" content="ARD Helpdesk" />
        <meta name="msapplication-TileColor" content="#003480" />
        <meta name="msapplication-TileImage" content="/static/favicons/mstile-144x144.png" />
        <meta name="msapplication-config" content="/static/favicons/browserconfig.xml" />
        <meta name="theme-color" content="#003480" />
      </Head>
      {dataAvailable && <Script src={`${APP_SETTINGS.trackingUri}`} type="application/javascript" strategy="beforeInteractive" />}
    </>
  );
};

export default Header;
