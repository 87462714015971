
import CookieUtils from "../../lib/cookie-util";
/**
 * Documentation:
 * https://developers.atinternet-solutions.com/javascript-en/advanced-features-javascript-en/tagging-a-reactjs-site-javascript-en/
 * https://developers.atinternet-solutions.com/piano-analytics/data-collection/how-to-send-events/consent
 */

class PianoAnalyticsService {
    constructor(windowRef, siteId) {
        if (windowRef?.pa) {
            windowRef.pdl = window.pdl || {};
            windowRef.pdl.requireConsent = true;
            windowRef.pdl.consent = {
                defaultPreset: {
                    PA: 'essential'
                }
            };

            windowRef.pa.setConfigurations({
                site: siteId,
                collectDomain: "https://image.ard.de",
                path: 'ard.gif',
                addEventURL: true
            });
            windowRef.pa.privacy.setMode('no-consent');

            windowRef._pac = windowRef._pac || {};
            windowRef._pac.privacyDefaultMode = 'essential';

            this.windowRef = windowRef;
            this.paq = this.windowRef._paq || [];

            if (CookieUtils.cookiesAccepted()) {
                this.windowRef.pa.consent.setMode('opt-in');
                this.windowRef.pa.privacy.setMode('optin');
            } else {
                this.windowRef.pa.consent.setMode('opt-out');
                this.windowRef.pa.privacy.setMode('optout');
            }
        }
    }

    sendPageDisplayEvent(eventData = {}) {
        try {
            if (this.windowRef && CookieUtils.cookiesAccepted()) {
                this.paq.push(['sendEvent', 'page.display', eventData]);
            }
        } catch (ex) {
            console.log(ex);
        }

        return null;
    }

    sendSearchResultDisplay(eventData = {}) {
        try {
            if (this.windowRef && CookieUtils.cookiesAccepted()) {
                this.paq.push(['sendEvent', 'internal_search_result.display', eventData]);
            }
        } catch (ex) {
            console.log(ex);
        }

        return null;
    }

    sendSearchResultClick(eventData = {}) {
        try {
            if (this.windowRef && CookieUtils.cookiesAccepted()) {
                this.paq.push(['sendEvent', 'internal_search_result.click', eventData]);
            }
        } catch (ex) {
            console.log(ex);
        }

        return null;
    }
}

export default PianoAnalyticsService;
