class CookieUtils {
  static cookieName = 'cookieConsent';

  static isCookieExisting() {
    if (typeof window !== 'undefined' && document.cookie) {
      return document.cookie.split('; ').some((row) => row.startsWith(this.cookieName));
    }

    return false;
  }

  static cookiesAccepted() {
    if (this.isCookieExisting()) {
      return (
        document.cookie
          .split('; ')
          .find((row) => row.startsWith(this.cookieName))
          ?.split('=')[1] == 1
      );
    }

    return false;
  }

  static acceptCookies() {
    document.cookie = this.cookieName + '=1;max-age=31536000;path=/';
    window?.pa?.consent?.setMode('opt-in');
    window?.pa?.privacy?.setMode('optin');
  }

  static declineCookies() {
    document.cookie = this.cookieName + '=0;path=/';
    window?.pa?.consent?.setMode('opt-out');
    window?.pa?.privacy?.setMode('optout');
  }
}

export default CookieUtils;
