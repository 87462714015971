import initPianoAnalytics from './PianoAnalytics';
import { uid } from 'react-uid';
import CookieUtils from "../../lib/cookie-util";

export function trackPageDisplay(_windowRef, data, title) {
    if (_windowRef && (_windowRef.lastTrackingUri !== _windowRef.location.href || !_windowRef.lastTrackingUri)) {
        const pianoInternet = new initPianoAnalytics(_windowRef, APP_SETTINGS.trackingSiteId);
        const trackingMeta = getPageTrackingMeta(_windowRef, data, title);

        if (trackingMeta && CookieUtils.cookiesAccepted() && _windowRef.pa?.consent?.getMode() === 'opt-in') {
            console.log('track:', APP_SETTINGS.trackingSiteId, pianoInternet, trackingMeta);

            pianoInternet.sendPageDisplayEvent(trackingMeta);
            _windowRef.lastTrackingUri = _windowRef.location.href;
        }
    }
}

export function trackSearchResultClick(_windowRef, searchPhrase, maxSearchResults, clickElementId, clickPositionNumber, isSuggestionClick) {
    if (_windowRef) {
        const pianoInternet = new initPianoAnalytics(_windowRef, APP_SETTINGS.trackingSiteId);
        if (CookieUtils.cookiesAccepted() && _windowRef.pa?.consent?.getMode() === 'opt-in') {
            pianoInternet.sendSearchResultClick({
                ise_keyword: searchPhrase,
                ise_click_rank: clickPositionNumber,
                ise_page: maxSearchResults,
                ise_item_id: clickElementId,
                ise_suggestion: isSuggestionClick
            });
        }
    }
}

export function trackSearchResultDisplay(_windowRef, searchPhrase, maxSearchResults) {
    if (_windowRef) {
        const pianoInternet = new initPianoAnalytics(_windowRef, APP_SETTINGS.trackingSiteId);
        if (CookieUtils.cookiesAccepted() && _windowRef.pa?.consent?.getMode() === 'opt-in') {
            pianoInternet.sendSearchResultDisplay({
                ise_keyword: searchPhrase,
                ise_page: maxSearchResults != 0 ? 1 : 0, //maxSearchResults
            });
        }
    }
}

function getPageTrackingMeta(_windowRef, data, title) {
    if (_windowRef) {
        const encodedTitle = encodeURIComponent(title);
        const pageName = data?.id ? data.id : encodedTitle;
        const currUri = _windowRef.location.href;
        const encodedUri = encodeURIComponent(currUri);
        const uri = new URL(currUri);
        const pathname = uri.pathname;
        const refferer = uri.hostname;
        const chapterSplitFull = pathname.split('/').filter(e => {
            return e !== '' && e != null;
        });
        let chapterSplit = [...chapterSplitFull];
        chapterSplit = chapterSplit.slice(0, -1);

        let pageType = 'Landingpage';
        let pageContent = null;

        const {
            global,
            category,
            topic,
            article,
            homepage,
            contact,
            search
        } = data;

        if (search) {
            pageContent = search;
            pageType = 'Search';
        }
        else if (contact) {
            pageContent = contact;
            pageType = 'Contact';
        }
        else if (homepage) {
            pageContent = homepage;
            pageType = 'Landingpage';
        }
        else if (article) {
            pageContent = article;
            pageType = 'ArticlePage';
        }
        else if (topic) {
            pageContent = topic;
            pageType = 'TopicPage';
        }
        else if (category) {
            pageContent = category;
            pageType = 'CategoryPage';
        }

        if (pageContent) {
            const pageId = `${pageType}_${pageContent.id}`;
            const { seo, siteName } = global;
            const seoWithDefaults = {
                ...seo,
                ...(pageContent?.attributes?.seo ?? {}),
            };

            return {
                page_chapter1: pageType,
                page_chapter2: category?.attributes?.title ?? '',
                page_chapter3: topic?.attributes?.title ?? '',
                page_title: `${seoWithDefaults.metaTitle} | ${siteName}`,
                page_id: pageId,
                content_publication_date: pageContent?.attributes?.createdAt ?? '',
                content_modification_date: pageContent?.attributes?.updatedAt ?? '',
            };
        }
    }

    return null;
}